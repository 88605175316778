import React from "react";
import styled from "styled-components";

const Header: React.FC = () => {
  const handleClose = () => {
    if (window.confirm("창을 닫으시겠습니까?")) {
      window.close();
    }
  };

  return (
    <StyledHeader>
      <img className="logo" src="https://cdn.builder.io/api/v1/image/assets/TEMP/dde04c462daaac6ecbac3f24afecfe22233d048d57379457d169ec5629f2569b?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9" alt="Company logo" />
      <h1 className="title">주문/결제</h1>
      <button className="close-btn" aria-label="Close" onClick={handleClose}>
        X
      </button>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Inter, sans-serif;
  color: #000;
  margin-bottom: 20px;
  padding: 0 20px;

  .logo {
    width: 142px;
    aspect-ratio: 2.9;
    object-fit: contain;
    margin-right: 20px;
  }

  .title {
    font-size: 45px;
    font-weight: 700;
    flex-grow: 1;
    text-align: center;
    margin-right: 100px;
  }

  .close-btn {
    font-size: 40px;
    background: none;
    border: none;
    cursor: pointer;
  }
`;

export default Header;