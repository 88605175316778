
import React from "react";
import styled from "styled-components";

interface ProductImageProps {
  src: string;
  alt: string;
}

const ProductImage: React.FC<ProductImageProps> = ({ src, alt }) => {
  return (
    <ImageContainer>
      <Image loading="lazy" src={src} alt={alt} />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 1.21;
  overflow: hidden;
  display: flex;
  align-items: flex-start; // 이미지를 상단에 붙임
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; 
  object-position: top center; // 이미지의 상단 중앙을 기준으로 배치
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default ProductImage;