/**
 * This code was generated by Builder.io.
 */
import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/LoginPage/Header";
import LoginForm from "../components/LoginPage/LoginForm";
import Footer from "../components/LoginPage/Footer";
import { useNavigate } from "react-router-dom";
import { useDelayedNavigation } from "../useDelayedNavigation";

const LoginPage: React.FC = () => {
  const delayedNavigate = useDelayedNavigation(1000);

  useEffect(() => {
    const existingMeta = document.querySelector('meta[name="viewport"]');
    if (existingMeta) {
      existingMeta.remove();
    }

    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1';
    document.head.appendChild(meta);

    return () => {
      if (meta) {
        meta.remove();
      }
    };
  }, []);

  const handleBack = async () => {
    console.log('Back button clicked');
    await delayedNavigate(-1);
    console.log('Navigation complete');
  };

  return (
    <StyledMain>
      <BackButton onClick={handleBack}>
        <img src="/assets/arrow-left.png" alt="Back" style={{ width: '30px', marginTop: '30px' }} />
      </BackButton>
      <Header />
      <LoginForm />
      <Footer />
    </StyledMain>
  );
};

const StyledMain = styled.main`
  background-color: #fff;
  display: flex;
  max-width: 864px;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
  padding: 109px 41px 836px;
  @media (max-width: 991px) {
    padding: 100px 20px;
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
`;

export default LoginPage;