import React from "react";
import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import styled from 'styled-components';
import ProductDetails from "./pages/ProductDetails";
import OrderPaymentPage from "./pages/OrderPayment";
import OrderComplete from "./pages/OrderComplete";
import ScrollToTop from "./ScrollTotop";
import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import { ProductProvider } from './context/ProductContext';

const AppContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
`;

const App: React.FC = () => {
  return (
    <AppContainer>
      <ProductProvider>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<ProductDetails />} />
            <Route path="/order-payment" element={<OrderPaymentPage />} />
            <Route path="/order-complete" element={<OrderComplete />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </ProductProvider>
    </AppContainer>
  );
};

export default App;
