/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

interface InputFieldProps {
  icon: string;
  label: string;
  type: string;
  id: string;
  value?: string; // Added: value property defined
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Added: onChange property defined
}

const InputField: React.FC<InputFieldProps> = ({ icon, label, type, id, value, onChange }) => {
  return (
    <InputContainer>
      <InputWrapper>
        <Icon loading="lazy" src={icon} alt={`${label} icon`} />
        <Input 
          type={type} 
          id={id} 
          name={id} 
          aria-label={label} 
          placeholder={label} 
          value={value} // Use value property
          onChange={onChange} // Use onChange property
        />
      </InputWrapper>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; // Changed: Reduced gap
  margin-bottom: 10px; // Changed: Reduced margin
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-left: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); // Changed: Added separator
  display: flex; // Changed: Use flexbox
  align-items: center; // Changed: Vertically align
`;

const Icon = styled.img`
  position: absolute; // Changed: Position icon
  left: -50px; // Changed: Move icon to the left of the input
  top: 50%;
  transform: translateY(-50%);
  width: 30px; // Icon size adjusted
  height: 30px; // Icon size adjusted
  pointer-events: none; // Prevent icon clicks
`;

const Input = styled.input`
  padding-left: 50px; // Changed: Move placeholder to the right
  padding-top: 10px;
  min-height: 40px; // Changed: Set minimum height
  min-width: 250px; // Changed: Set minimum width
  width: 100%;
  flex: 1;
  border: none; // Changed: Removed border
  padding: 8px; // Changed: Reduced padding
  display: flex; // Changed: Use flexbox
  align-items: center; // Changed: Vertically align

  // Changed: Increase placeholder font size
  &::placeholder {
    font-size: 18px;
    font-weight: 500;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default InputField;