import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import Header from "../components/OrderPayment/Header";
import OrderSummary from "../components/OrderPayment/OrderSummary";
import DeliveryAddress from "../components/OrderPayment/DeliveryAddress";
import PayMethod from "../components/OrderPayment/PayMethod";
import Footer from "../components/OrderPayment/Footer";
import { useDelayedNavigation } from "../useDelayedNavigation";
import { ProductContext } from '../context/ProductContext';

const OrderPaymentPage: React.FC = () => {
  const delayedNavigate = useDelayedNavigation();
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("ProductContext를 찾을 수 없습니다.");
  }

  const { isLoading } = context;

  useEffect(() => {
    // 기존 viewport 메타 태그 제거
    const existingMeta = document.querySelector('meta[name="viewport"]');
    if (existingMeta) {
      existingMeta.remove();
    }

    // 새로운 viewport 메타 태그 추가
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
    document.head.appendChild(meta);

    return () => {
      // 컴포넌트 언마운트 시 메타 태그 제거
      if (meta) {
        meta.remove();
      }
    };
  }, []);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <ContainerWrapper>
      <PageWrapper>
        <ScaledContent>
          <Header />
          <main>
            <OrderSummary />
            <DeliveryAddress />
            <PayMethod />
          </main>
          <Footer />
        </ScaledContent>
      </PageWrapper>
    </ContainerWrapper>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f5f7;
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #27C360;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 1200px);
  background-color: #f4f5f7;
  overflow-x: hidden;
`;

const PageWrapper = styled.div`
  background-color: #f4f5f7;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  padding: 20px;  /* 좌우 여백 추가 */
  overflow: hidden;
`;

const ScaledContent = styled.div`
  transform-origin: top center;
  @media (max-width: 864px) {
    transform: scale(0.47619); // 412 / 864 ≈ 0.47619
    width: 210%; // 스케일 다운에 맞춰 너비를 조정 (1 / 0.47619 ≈ 2.10)
    margin-left: -55%; // 중앙 정렬을 위해 좌측 마진 조정
  }
`;

export default OrderPaymentPage;