import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://apckyngwxizrzhokcfhs.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFwY2t5bmd3eGl6cnpob2tjZmhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzE3NzY4MTksImV4cCI6MjA0NzM1MjgxOX0.Zhcni5Nau8Gviwe4wTEZSjNRGxCO_kpGBRs2sOKDucI';

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true
  },
  global: {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'authorization, x-client-info, apikey, content-type'
    }
  }
});