import React, { useContext } from "react";
import styled from "styled-components";
import { ProductContext } from '../../context/ProductContext';

const OrderSummary: React.FC = () => {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("ProductContext를 찾을 수 없습니다.");
  }

  const { orderSummary, productInfo, productImage } = context;

  return (
    <Section>
      <SectionTitle>주문상품</SectionTitle>
      <CardContainer>
        <ArrowIcon src="/assets/arrow-up.png" alt="Arrow Up" />
        <ProductCard>
          <ShippingLabel>{orderSummary.shippingLabel}</ShippingLabel>
          <ProductDetails>
            <ProductImage
              src={productImage}
              alt="Product"
            />
            <ProductInfo>
              <ProductName>{productInfo.title}</ProductName>
              <ProductPrice>{productInfo.price}</ProductPrice>
            </ProductInfo>
          </ProductDetails>
        </ProductCard>
      </CardContainer>
    </Section>
  );
};

const Section = styled.section`
  margin-top: 77px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 45px;
  color: #000;
  font-weight: 700;
  padding: 10px;
  margin-left: 5px; /* 텍스트를 오른쪽으로 이동 */
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const CardContainer = styled.div`
  position: relative; /* ArrowIcon의 위치를 제어하기 위해 사용 */
`;

const ProductCard = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 45px 21px;
  margin-top: 5px;
  position: relative;
  min-height: 294px;
  display: flex;
  align-items: center; /* 세로 중앙 정렬 */
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const ArrowIcon = styled.img`
  position: absolute;
  top: -95px; /* 상자 바깥쪽 위로 이동 */
  right: 20px; /* 상자 바깥쪽 오른쪽으로 이동 */
  width: 30px; /* 이미지 크기 조정 */
  height: 30px;
`;

const ShippingLabel = styled.span`
  font-size: 34px;
  color: #767677;
  font-weight: 482;
  position: absolute;
  top: 40px; /* 살짝 아래로 이동 */
  right: 20px;
  @media (max-width: 991px) {
    top: 40px; /* 모바일에서도 동일한 위치 */
    right: 20px;
  }
`;

const ProductDetails = styled.div`
  display: flex;
  align-items: center; /* 세로 중앙 정렬 */
  gap: 12px;
  margin-top: 0; /* 중앙 정렬을 위해 상단 마진 제거 */
  width: 100%; /* 가로 공간을 충분히 차지하도록 설정 */
`;

const ProductImage = styled.img`
  width: 205px;
  height: 204px;
  object-fit: cover;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 35px;
  font-weight: 482;
  flex-grow: 1; /* 가로 공간을 충분히 차지하도록 설정 */
`;

const ProductName = styled.span`
  color: #000;
  margin-bottom: 10px; /* 상품명을 약간 위로 올리기 위해 마진 추가 */
  font-weight: 600;
`;

const ProductPrice = styled.span`
  color: #000;
  margin-top: 24px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

export default OrderSummary;
