import { useNavigate, To, NavigateOptions } from 'react-router-dom';
import { useCallback } from 'react';

export const useDelayedNavigation = (delay: number = 1000) => {
  const navigate = useNavigate();

  const delayedNavigate = useCallback(
    (to: To | number, options?: NavigateOptions) => {
      console.log(`Delaying navigation for ${delay}ms`);  // 디버깅용 로그
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          console.log('Navigating now');  // 디버깅용 로그
          if (typeof to === 'number') {
            navigate(to);
          } else {
            navigate(to, options);
          }
          resolve();
        }, delay);
      });
    },
    [navigate, delay]
  );

  return delayedNavigate;
};