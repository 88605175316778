import React from "react";
import styled from "styled-components";

interface SellerInfoProps {
  name: string;
  contact: string;
  reviews: {
    positive: number;
    neutral: number;
    negative: number;
  };
}

const SellerInfoWrapper: React.FC<SellerInfoProps> = (props) => {
  return (
    <Wrapper>
      <HorizontalLine />
      <SellerInfo {...props} />
    </Wrapper>
  );
};

const SellerInfo: React.FC<SellerInfoProps> = ({ name, contact, reviews }) => {
  const [emailAddress, phoneNumber] = contact.split(" ㅣ ");

  return (
    <ProfileContainer>
      <SellerLabel>판매자</SellerLabel>
      <ProfileContent>
        <SellerInfoSection
          name={name}
          emailAddress={emailAddress}
          phoneNumber={phoneNumber}
        />
        <VerificationStatus />
        <ReviewStats counts={reviews} />
      </ProfileContent>
    </ProfileContainer>
  );
};

const Wrapper = styled.div`
  margin-top: 40px;
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 1px;
  background-color: #e0e0e0;
  margin-bottom: 40px;
`;

const ProfileContainer = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  font-family: Inter, sans-serif;
  font-weight: 475;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  transform: scale(0.95);
`;

const SellerLabel = styled.h2`
  color: rgba(159, 159, 159, 1);
  font-size: 17px;
  margin-top: 5px;
  margin-left: 17px;
  @media (max-width: 640px) {
    margin-top: 0px;
  }
`;

const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px; /* 기존 22px에서 줄임 */
  justify-content: flex-start;
  width: 350px; /* 기존 412px에서 줄임 */
`;

interface SellerInfoSectionProps {
  name: string;
  emailAddress: string;
  phoneNumber: string;
}

const SellerInfoSection: React.FC<SellerInfoSectionProps> = ({
  name,
  emailAddress,
  phoneNumber,
}) => {
  return (
    <InfoContainer>
      <AvatarContainer>
        <Avatar />
        <NameContainer>
          <SellerName>{name}</SellerName>
          <VerifiedIcon
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/95d39828fffc5d56f07aa151055aaefdf0af42fc8ae8128a0201fa784959938f?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
            alt="인증됨"
          />
        </NameContainer>
      </AvatarContainer>
      <ContactInfo>
        {emailAddress} ㅣ {phoneNumber}
      </ContactInfo>
    </InfoContainer>
  );
};

const InfoContainer = styled.div`
  align-self: stretch;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 23px;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  white-space: nowrap;
  justify-content: flex-start;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Avatar = styled.div`
  background-image: url('/assets/profile.png');
  background-size: cover;
  border-radius: 50%;
  align-self: stretch;
  display: flex;
  width: 39px;
  height: 40px;
  margin: auto 0;
  margin-left: 10px;
`;


const NameContainer = styled.div`
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  justify-content: flex-start;
  margin: 0; /* 간격 줄이기 위해 margin 제거 */
  margin-top: 3px; /* 판매자 이름을 아래로 이동 */
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const SellerName = styled.span`
  font-weight: 700;
`;

const VerifiedIcon = styled.img`
  aspect-ratio: 1.04;
  object-fit: contain;
  object-position: center;
  width: 26px;
`;

const ContactInfo = styled.p`
  align-self: stretch;
  gap: 10px;
  
  color: rgba(0, 0, 0, 1);
  padding: 15px 0 5px 10px; /* 위쪽 패딩과 아래쪽 패딩을 줄임 */
  margin: 0; /* 간격 줄이기 위해 margin 제거 */
  @media (max-width: 640px) {
    font-weight: 600;
  }
`;

const VerificationStatus: React.FC = () => {
  return (
    <StatusContainer>
      <StatusText>
        본인인증 완료 <TextSeparator>ㅣ</TextSeparator> 연락처 및 의심정보 조회
      </StatusText>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
  color: rgba(39, 195, 96, 1);
  justify-content: flex-start;
`;

const StatusText = styled.p`
  align-self: stretch;
  min-width: 240px;
  gap: 10px;
  margin: auto 0;
  padding: 10px;
  font-weight: 475;
  letter-spacing: -1px;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  @media (max-width: 640px) {
    font-weight: 500;
    min-width: 450px;
    padding-right: 19px;
  }
`;

const TextSeparator = styled.span`
  color: rgba(217, 217, 217, 1);
`;

interface ReviewStatsProps {
  counts: {
    positive: number;
    neutral: number;
    negative: number;
  };
}

const ReviewStats: React.FC<ReviewStatsProps> = ({ counts }) => {
  const reviewTypes = [
    {
      type: "positive",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/acacc4e3671f6e2a0d47c2140ae17232bd7353cec6640f495e95e693f153567c?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9",
    },
    {
      type: "neutral",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1d64ca65adeafe2bd016f8b5d91ad1a61fd81130f2bade69c8865120747acc3e?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9",
    },
    {
      type: "negative",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/182d61936efecddf195b606d623e810d97b3e5b9400038e503f32a901221a8dc?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9",
    },
  ];

  return (
    <StatsContainer>
      <ReviewContainer>
        <ReviewLabelStyled>거래 후기</ReviewLabelStyled>
        {reviewTypes.map((review) => (
          <ReviewItem key={review.type}>
            <ReviewIcon
              loading="lazy"
              src={review.icon}
              alt={`${review.type} review icon`}
            />
            <ReviewCount>
              {counts[review.type as keyof typeof counts]}
            </ReviewCount>
          </ReviewItem>
        ))}
      </ReviewContainer>
      <MoreIcon
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9ad1031e9c61978e385b00fffd4bb90a96d4501af79840b972444e76ccb5f026?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
        alt="더 보기"
      />
    </StatsContainer>
  );
};

const StatsContainer = styled.div`
  align-self: flex-start;
  display: flex;
  padding-left: 10px;
  align-items: center;
  gap: 40px 45px;
  color: rgba(0, 0, 0, 1);
  justify-content: flex-start;
`;

const ReviewContainer = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 5px; /* 기존 5x에서 10px로 변경 */
  justify-content: flex-start;
  width: 307px;
  margin: auto 0;
`;

const ReviewLabelStyled = styled.span`
  align-self: stretch;
  margin: auto 0;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  @media (max-width: 640px) {
    min-width: 50px;
  }
`;

const ReviewItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ReviewIcon = styled.img`
  aspect-ratio: 1.07;
  object-fit: contain;
  object-position: center;
  width: 46px;
  align-self: stretch;
  margin: auto 0;
`;

const ReviewCount = styled.span`
  align-self: stretch;
  margin: auto 0;
`;

const MoreIcon = styled.img`
  aspect-ratio: 0.59;
  object-fit: contain;
  object-position: center;
  width: 10px;
  align-self: stretch;
  margin: auto 0;
`;

export default SellerInfoWrapper;