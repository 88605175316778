import React, { useState } from "react";
import styled from "styled-components";

interface PaymentMethodProps {
  setSelectedPaymentMethod: (method: string | null) => void;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({ setSelectedPaymentMethod }) => {
  const [selectedBank, setSelectedBank] = useState<string | null>(null);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedBank(selectedValue);
    setSelectedPaymentMethod(selectedValue);
  };

  return (
    <Section>
      <SectionTitle>결제수단</SectionTitle>
      <PaymentContainer>
        <ArrowIcon src="/assets/arrow-up.png" alt="Arrow Up" />
        <PaymentOptions>
          <PaymentOption>
            <JungonaraPayLogo
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/27df8873ff4179db0392ff1469b4be10171cf3a2b1f43948cb6c42de7f6f700b?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
              alt="중고나라 페이 로고"
            />
            <span>무통장입금</span>
          </PaymentOption>
          <Select onChange={handleSelectChange} value={selectedBank || ""}>
            <option value="" disabled>은행을 선택하세요</option>
            <option value="케이뱅크">케이뱅크</option>
            <option value="토스뱅크">토스뱅크</option>
          </Select>
          <PaymentInfo>
          </PaymentInfo>
        </PaymentOptions>
      </PaymentContainer>
    </Section>
  );
};

const Section = styled.section`
  margin-top: 39px;
`;

const SectionTitle = styled.h2`
  font-size: 45px;
  color: #000;
  font-weight: 700;
  padding: 10px;
  margin-left: 10px; /* 텍스트를 오른쪽으로 이동 */
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const PaymentContainer = styled.div`
  position: relative; /* ArrowIcon의 위치를 제어하기 위해 사용 */
`;

const ArrowIcon = styled.img`
  position: absolute;
  top: -80px; /* 상자 바깥쪽 위로 이동 */
  right: 20px; /* 상자 바깥쪽 오른쪽으로 이동 */
  width: 30px; /* 이미지 크기 조정 */
  height: 30px;
`;

const PaymentOptions = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 29px;
  margin-top: 5px;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const PaymentOption = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 35px;
  font-weight: 500;
  color: #000;
`;

const JungonaraPayLogo = styled.img`
  width: 70px; /* 중고나라 페이 로고 크기 */
  height: auto; /* 비율을 유지하며 크기 조정 */
`;

const PaycoLogo = styled.img`
  width: 200px; /* 페이코 로고 크기 */
  height: auto; /* 비율을 유지하며 크기 조정 */
`;

const Select = styled.select`
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 60px; /* 크기 줄임 */
  color: #000;
  font-size: 30px; /* 글꼴 크기 줄임 */
  font-weight: 482;
  margin-top: 25px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
  max-height: 200px;

  &:first-of-type {
    margin-top: 33px;
  }

    option {
    font-size: 18px; /* option 텍스트 크기 줄임 */
    padding: 5px; /* option 내부 여백 조정 */
  }

`;

const PaymentInfo = styled.div`
  margin-top: 34px;
  color: #a3a4a6;
  font-size: 28px;
  font-weight: 654;
`;

const InfoTitle = styled.h3`
  margin-bottom: 24px;
`;

const InfoImage = styled.img`
  width: 100%;
  aspect-ratio: 1.64;
  object-fit: contain;
`;

export default PaymentMethod;
