import React from "react";
import styled from "styled-components";

const TransactionMethods: React.FC = () => {
  return (
    <Container>
      <FullImage src="/assets/transmeth.png" alt="거래 방법" />
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
`;

const FullImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export default TransactionMethods;