/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

interface AdBannerProps {
  src: string;
  alt: string;
}

const AdBanner: React.FC<AdBannerProps> = ({ src, alt }) => {
  return <Banner loading="lazy" src={src} alt={alt} />;
};

const Banner = styled.img`
  width: 95%;
  aspect-ratio: 4.85;
  object-fit: contain;
  margin-top: 26px;
  @media (max-width: 991px) {
    max-width: 95%;
  }
`;

export default AdBanner;
