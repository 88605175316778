import React from "react";
import styled from "styled-components";

interface ProductHeaderProps {
  title: string;
}

const ProductHeader: React.FC<ProductHeaderProps> = ({ title }) => {
  const handleRedirect = () => {
    window.location.href = "https://cafe.naver.com/joonggonara.cafe";
  };

  return (
    <Header>
      <LogoWrapper onClick={handleRedirect}>
        <Logo
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e678ac9811848ee999eb89344c45eb8801a73f61bae018082c211fdd0d84c856?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
          alt="이전 버튼"
        />
      </LogoWrapper>
      <Title onClick={handleRedirect}>{title}</Title>
      <MenuIcon
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6118cc03b4487d713c57297d9538be5d497cc1dad454beb5508a066727e4becc?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
        alt="메뉴 아이콘"
      />
    </Header>
  );
};

const Header = styled.header`
  background-color: #00ab57;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px; /* 패딩 값을 줄임 */
  position: fixed; /* 화면 상단에 고정 */
  top: 0; /* 화면의 위쪽에 위치 */
  left: 0; /* 화면의 왼쪽에 위치 */
  width: 100%; /* 전체 너비를 차지 */
  z-index: 1000; /* 다른 요소들 위에 표시되도록 z-index 설정 */
  @media (max-width: 991px) {
    padding: 5px 5px; /* 모바일에서도 패딩 값을 줄임 */
  }
`;

const LogoWrapper = styled.div`
  cursor: pointer;
  margin-right: 20px;
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  cursor: pointer;
  margin-right: auto;
`;

const MenuIcon = styled.img`
  width: 12px;
  height: 32px;
  margin-right: 25px;
`;

export default ProductHeader;