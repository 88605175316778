import React from "react";
import styled from "styled-components";
import { useContext } from "react";
import { ProductContext } from "../../context/ProductContext";

interface ProductInfoProps {
  topCategory: string;
  bottomCategory: string;
  title: string;
  description: string;
  price: string;
  status: string;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  topCategory,
  bottomCategory,
  title,
  description,
  price,
  status,
}) => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("ProductContext를 찾을 수 없습니다.");
  }
  const { productInfo } = context;

  return (
    <InfoContainer>
      <Category>{topCategory}</Category>
      <TitleWrapper>
        <Status>{status}</Status>
        <Title>{title}</Title>
      </TitleWrapper>
      <Details>
        <Price>{price}</Price>
        <CategoryTag>{bottomCategory}</CategoryTag>
        <MetaInfo>
          <span>{productInfo.date}</span>
          <span>조회 {productInfo.views}</span>
        </MetaInfo>
      </Details>
    </InfoContainer>
  );
};

const InfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 20px;
`;

const Category = styled.p`
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  letter-spacing: -1.5px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: -15px;
`;

const Status = styled.span`
  color: #0fc05e;
  font-size: 28px;
  font-weight: 475;
`;

const Title = styled.h2`
  color: #000;
  font-size: 28px;
  font-weight: 475;
  margin: 0;
  letter-spacing: -2px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #000;
`;

const Price = styled.span`
  font-size: 33px;
  font-weight: 600;
  margin-top: 10px;
`;

const CategoryTag = styled.span`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -1.5px;
  margin-bottom: 5px;
`;

const MetaInfo = styled.div`
  display: flex;
  gap: 15px;
  font-size: 21px;
  color: #a3a4a6;
  font-weight: 500;
  margin-top: -5px;
`;

export default ProductInfo;