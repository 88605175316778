import React from "react";
import styled from "styled-components";

const DeliveryAddress: React.FC = () => {
  return (
    <Section>
      <SectionTitle>배송지</SectionTitle>
      <FormContainer>
        <ArrowIcon src="/assets/arrow-up.png" alt="Arrow Up" />
        <AddressForm>
        <AddressInput placeholder=" 받는 이" />
          <AddressInput placeholder=" 주소" />
          <AddressInput placeholder=" 상세주소(동,호수)" />
          <PhoneInputContainer>
            <PhoneInput placeholder="010" />
            <PhoneSeparator>-</PhoneSeparator>
            <PhoneInput placeholder="" />
            <PhoneSeparator>-</PhoneSeparator>
            <PhoneInput placeholder="" />
          </PhoneInputContainer>
        </AddressForm>
      </FormContainer>
    </Section>
  );
};

const Section = styled.section`
  margin-top: 95px;
  padding: 0 20px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 45px;
  color: #000;
  font-weight: 700;
  margin-left: 10px; /* 텍스트를 오른쪽으로 이동 */
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const FormContainer = styled.div`
  position: relative; /* ArrowIcon의 위치를 제어하기 위해 사용 */
`;

const AddressForm = styled.form`
  background-color: #fff;
  border-radius: 20px;
  margin-top: 18px;
  padding: 33px 66px;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const ArrowIcon = styled.img`
  position: absolute;
  top: -90px; /* 상자 바깥쪽 위로 이동 */
  right: 20px; /* 상자 바깥쪽 오른쪽으로 이동 */
  width: 30px; /* 이미지 크기 조정 */
  height: 30px;
`;

const AddressInput = styled.input`
  width: 97%;
  font-size: 35px;
  margin-bottom: 20px; /* 간격을 줄이기 위해 조정 */
  border: none; /* 테두리 제거 */
  border-bottom: 1px solid #ddd; /* 하단 테두리 추가 */
  padding: 10px 0; /* 상하 패딩 조정 */

  &:last-child {
    margin-bottom: 0;
  }
`;

const PhoneInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PhoneInput = styled.input`
  width: 30%;
  font-size: 35px;
  border: none; /* 테두리 제거 */
  border-bottom: 1px solid #ddd; /* 하단 테두리 추가 */
  padding: 10px 0; /* 상하 패딩 조정 */
  text-align: center;
  margin-bottom: 20px; /* 간격을 줄이기 위해 조정 */

  &:nth-child(1) {
    width: 20%; /* 첫 번째 칸을 약간 좁게 조정 */
  }

  &:nth-child(3) {
    width: 35%; /* 세 번째 칸의 너비를 맞춰서 다른 입력란들과 정렬 */
  }
`;

const PhoneSeparator = styled.span`
  font-size: 35px;
  padding: 0 5px; /* 구분자의 간격을 줄여서 정렬에 맞춤 */
`;

export default DeliveryAddress;
