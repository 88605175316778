import React from 'react';
import styled from 'styled-components';

interface ProductContentProps {
  content: string;
  date: string;
  commentCount: number;
}

const ProductContent: React.FC<ProductContentProps> = ({ content }) => {
  console.log('Content received:', content); // 디버깅용 로그

  return (
    <Container>
      <ContentSection>
        <Content>{content || "상품 설명이 없습니다."}</Content>
      </ContentSection>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 20px;
  background-color: white;
  margin-bottom: 10px;
`;

const ContentSection = styled.div`
  margin: 0;
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 1.4;
  color: #333;
  white-space: pre-wrap;
  max-height: 50px;
  overflow-y: auto;
  margin: 0;
`;

export default ProductContent;