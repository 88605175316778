/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <FooterImage
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/630037abc4e4f58ab8d6e25ba8b0e7d735815e94192854e892ae5b012d29e3f3?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
        alt="Footer image"
      />
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  align-self: center;
  display: flex;
  margin-top: 26px;
  width: 100%; // Changed: Fixed width to 100%
  max-width: 642px; // Changed: Added max-width
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const FooterImage = styled.img`
  aspect-ratio: 13.89;
  object-fit: contain;
  margin-left: 8%;
  object-position: center;
  width: 80%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default Footer;