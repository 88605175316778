import React, { useState } from "react";
import styled from "styled-components";
import InputField from "./InputField";
import { useNavigate } from "react-router-dom";
import { useDelayedNavigation } from "../../useDelayedNavigation"; // 경로는 실제 위치에 맞게 조정해주세요

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const delayedNavigate = useDelayedNavigation(1000); // 1초 딜레이

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (username && password) {
      setIsLoading(true);
      console.log("Login submitted, navigating to order-payment page"); // 디버깅용 로그
      await delayedNavigate("/order-payment");
      console.log("Navigation to order-payment page complete"); // 디버깅용 로그
      setIsLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <KeyboardToggle>
        <KeyboardToggleText>pc 키보드 보기</KeyboardToggleText>
        <KeyboardIcon
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/484cfa7cd636d6d5dd42ab9b63874dacccf8caaa5e8dbda88dc13a1e8b3a85c9?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
          alt="Keyboard icon"
        />
      </KeyboardToggle>
      <FormContainer>
        <InputField
          icon="https://cdn.builder.io/api/v1/image/assets/TEMP/e2026561bb9ea14b2e4d57af97c91d5bb2ffd405a4ec1f1ffa0a6fe8afccac0f?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
          label="아이디"
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <InputField
          icon="https://cdn.builder.io/api/v1/image/assets/TEMP/d3eb52d5bb19385cadd59342cbf3a3315cf962374b4bc5d02a34cb9e8de8a9fa?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
          label="비밀번호"
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormContainer>
      <SubmitButton 
        type="submit" 
        style={{ 
          backgroundColor: username && password ? "#00ab57" : "#8990a0",
          cursor: isLoading ? "not-allowed" : "pointer"
        }}
        disabled={isLoading}
      >
        {isLoading ? "로그인" : "로그인"}
      </SubmitButton>
    </StyledForm>
  );
};



const StyledForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 412px) { // 모바일 화면에 맞게 조정
    transform: scale(0.95); // 전체 배율 줄이기
    transform-origin: top; // 변환 기준점 설정
  }
`;

const KeyboardToggle = styled.div`
  align-self: flex-end;
  display: flex;
  margin-left: 10px; // 변경: 우측 패딩 추가
  align-items: right;
  gap: 0px;
  justify-content: flex-end; // 변경: flex-start에서 flex-end로 수정
  @media (max-width: 991px) {
    padding-right: 10px; // 모바일에서도 동일하게 적용
  }
`;

const KeyboardToggleText = styled.span`
  color: #9f9f9f;
  padding-left: 10px;
  font: 482 14px Inter, sans-serif;
`;

const KeyboardIcon = styled.img`
  aspect-ratio: 1.73;
  object-fit: contain;
  object-position: center;
  width: 12px;
  align-self: stretch;
  margin-left : 5px;
`;

const FormContainer = styled.div`
  border-radius: 20px; // 경계 반경 줄이기
  background-color: #fff;
  display: flex;
  margin-top: 10px; // 마진 줄이기
  margin-left: -10px;
  min-height: 80px;
  width: 100%; // 변경: 너비를 늘리기 위해 수정
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0px 30px; // 패딩 줄이기
  border: 1px solid #9f9f9f; // 변경: 테두리 두께를 1px로 줄이고 색상은 동일하게 유지
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
  }
`;

const SubmitButton = styled.button`
  align-self: stretch;
  border-radius: 15px; // 경계 반경 줄이기
  background-color: #8990a0; // 기본 배경색
  margin-top: 30px; // 마진 줄이기
  min-height: 65px; // 최소 높이 줄이기
  max-height: 120px;
  width: 107%;
  color: #fff;
  text-align: center;
  padding: 30px; // 패딩 줄이기
  font: 500 23px Inter, sans-serif; // 폰트 크기 줄이기
  border: none;
  cursor: pointer;
  margin-left: -10px; // 추가: 버튼을 왼쪽으로 10px 이동

  @media (max-width: 991px) {
    max-width: 110%;
    margin-top: 20px;
    padding: 10px;
  }
`;

export default LoginForm;