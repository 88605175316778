import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useDelayedNavigation } from "../useDelayedNavigation";
import { ProductContext } from '../context/ProductContext';

const OrderComplete: React.FC = () => {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("ProductContext를 찾을 수 없습니다.");
  }

  const { orderCompleteInfo, productImage, isLoading, productInfo } = context;
  const delayedNavigate = useDelayedNavigation();

  useEffect(() => {
    // 기존 viewport 메타 태그 제거
    const existingMeta = document.querySelector('meta[name="viewport"]');
    if (existingMeta) {
      existingMeta.remove();
    }

    // 새로운 viewport 메타 태그 추가
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
    document.head.appendChild(meta);

    return () => {
      if (meta) {
        meta.remove();
      }
    };
  }, []);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <ContainerWrapper>
      <PageWrapper>
        <ScaledContent>
          <Header />
          <section className="order-content">
            <ProductDetails 
              productName={orderCompleteInfo.productName} 
              price={productInfo.price}
              productImage={productImage}
            />
            <BankDetails bankName={orderCompleteInfo.bankName} accountNumber={orderCompleteInfo.accountNumber} />
            <ContactSeller />
          </section>
          <Footer />
        </ScaledContent>
      </PageWrapper>
    </ContainerWrapper>
  );
};

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f5f7;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const PageWrapper = styled.div`
  background-color: #f4f5f7;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 864px;
  height: 100vh;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden;
`;

const ScaledContent = styled.div`
  transform-origin: top center;
  @media (max-width: 864px) {
    transform: scale(0.47619); // 412 / 864 ≈ 0.47619
    width: 210%; // 스케일 다운에 맞춰 너비를 조정 (1 / 0.47619 ≈ 2.10)
    margin-left: -55%; // 중앙 정렬을 위해 좌측 마진 조정
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f5f7;
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #27C360;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Header: React.FC = () => {
  const handleClose = () => {
    if (window.confirm("창을 닫으시겠습니까?")) {
      window.close(); // 창 닫기
    }
  };

  return (
    <StyledHeader>
      <img className="logo" src="https://cdn.builder.io/api/v1/image/assets/TEMP/dde04c462daaac6ecbac3f24afecfe22233d048d57379457d169ec5629f2569b?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9" alt="Company logo" />
      <h1 className="title">주문완료</h1>
      <button className="close-btn" aria-label="Close" onClick={handleClose}>
        X
      </button>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Inter, sans-serif;
  color: #000;
  margin-bottom: 20px;
  padding: 0 20px;
  white-space: nowrap;

  .logo {
    width: 142px;
    aspect-ratio: 2.9;
    object-fit: contain;
    margin-right: 20px;
  }

  .title {
    font-size: 40px;
    font-weight: 700;
    flex-grow: 1;
    text-align: center;
    margin-left: 100px;
    margin-right: 250px;
  }

  .close-btn {
    display: none;
  }
`;

interface ProductDetailsProps {
  productName: string;
  price: string;
  productImage: string;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ productName, price, productImage }) => {
  return (
    <StyledProductDetails>
      <div className="product-image">
        <img src={productImage} alt={productName} />
      </div>
      <div className="product-info">
        <h2 className="product-name">
        {productName}
        </h2>
        <div className="price-info">
          <span className="price-label">결제금액</span>
          <span className="price-value">{price}</span>
        </div>
      </div>
    </StyledProductDetails>
  );
};

const StyledProductDetails = styled.section`
  display: flex;
  align-items: flex-end;
  gap: 19px;
  font-weight: 700;
  margin-bottom: 20px;

  .product-image {
    width: 205px;
    height: 204px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 991px) {
      width: 180px;
      height: 180px;
    }
  }

  .product-info {
    flex-grow: 1;
    font-size: 35px;
  }

  .product-name {
    font-size: 45px;
    margin-bottom: 86px;
    color: #000;

    .highlight {
      font-weight: 482;
    }

    @media (max-width: 991px) {
      margin-bottom: 40px;
    }
  }

  .price-info {
    display: flex;
    gap: 29px;
    color: #00ab57;
  }
`;

interface BankDetailsProps {
  bankName: string;
  accountNumber: string;
}

const BankDetails: React.FC<BankDetailsProps> = ({ bankName, accountNumber }) => {
  return (
    <StyledBankDetails>
      <div className="account-info">
        <span className="bank-name">{bankName}</span>
        <span className="account-number">{accountNumber}</span>
      </div>
      <p className="notification">입금이 완료되면 판매자가 알림을 받습니다.</p>
    </StyledBankDetails>
  );
};

const StyledBankDetails = styled.section`
  margin-top: 40px;

  .account-info {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 40px;
    color: #000;
    font-weight: 700;
    flex-wrap: wrap;
  }

  .notification {
    color: #00ab57;
    font-size: 30px;
    font-weight: 475;
    margin-top: 11px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContactSeller: React.FC = () => {
  const handleContact = () => {
    window.location.href = "https://orders.pay.naver.com";
  };

  return (
    <StyledContactSeller>
      <button className="contact-btn" onClick={handleContact}>판매자에게 연락하기</button>
    </StyledContactSeller>
  );
};

const StyledContactSeller = styled.section`
  margin-top: 61px;
  display: flex;
  justify-content: center;

  .contact-btn {
    width: 90%;
    border-radius: 14px;
    background-color: #00ab57;
    color: #fff;
    font: 600 35px Inter, sans-serif;
    padding: 38px 0;
    border: none;
    cursor: pointer;
    margin-left: 0px;
  }

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Footer: React.FC = () => {
  const handleNaverRedirect = () => {
    window.location.href = "https://orders.pay.naver.com";
  };

  return (
    <StyledFooter>
      <img className="footer-image" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5ad8d327ebdca2b2f62a497286bb27f027721295297f27896b3def076405d3ab?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9" alt="Footer decoration" />
      <img 
        className="bottom-image" 
        src="/assets/ordercomplete-bottom.png" 
        alt="Bottom decoration" 
        onClick={handleNaverRedirect}
        style={{ cursor: 'pointer' }}
      />
      <div className="logo-container">
        <img className="logo" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d74323226fb5acfcb04a28f5135ae6dc3b5e45cb203006cde9a66bc8805fe5d?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9" alt="Company logo" />
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  margin-top: 50px;

  .footer-image {
    width: 100%;
    aspect-ratio: 5.21;
    object-fit: contain;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    margin-top: 19px;
  }

  .logo {
    width: 254px;
    aspect-ratio: 3.72;
    object-fit: contain;
  }

  .bottom-image {
    display: block;
    margin: 0 auto;
    width: auto;
    margin-left: -40px;
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export default OrderComplete;