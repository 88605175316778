/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Header: React.FC = () => {
  return (
    <StyledHeader>
      <Logo
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets%2F4c6e8411f77f4625a1a5570dd0e6c4a9%2F63c293b148e44e47b785cf7b54c2d547"
        alt="Logo"
      />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 0 35px; // Changed: Reduced margin to make space
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 20px; // Changed: Reduced margin on mobile
  }
`;

const Logo = styled.img`
  aspect-ratio: 4.74;
  object-fit: cover;
  object-position: center;
  width: 45%;
  min-height: 20px;
  max-height: 80px;
  min-width: 20px;
  overflow: hidden;
  margin: 0 auto 50px;
  transform: translateX(-15px); /* 로고를 왼쪽으로 10px 이동 */
`;

export default Header;