import React from 'react';
import styled from 'styled-components';

interface FooterProps {
  likeCount: number;
  viewCount: number;
  date: string;
}

const Footer: React.FC<FooterProps> = ({ likeCount, viewCount, date }) => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterWrapper>
          <BackIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5592e6f2d4e5db121b3a8d5012141741cb4d4b4ac9dba5842b5399a6e196b1ec?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9" />
          <BackToList>목록으로</BackToList>
          <FooterActions>
            <LikeSection>
              <LikeIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d53ed9f86dcd0de6e8b72e70a167202aa30626af99e70828bfbb91827c2f2e8?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9" />
              <LikeCount>{likeCount}</LikeCount>
              <CommentIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b63b1a0f490cab399e4679810e09f7772f81e1a049a7ab995470c845f6563869?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9" />
              <CommentCount>0</CommentCount>
            </LikeSection>
            <ShareIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d9f4c691521c504bc510b9dbc02c6cf0382746d422bdbc9caf2e8b9a3c54ab9?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9" />
          </FooterActions>
        </FooterWrapper>
      </FooterContent>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 9%;
  max-width: 640px;
  flex-direction: column;
  font-size: 19px; /* 글자 크기 줄이기 */
  font-weight: 300;
  white-space: nowrap;
  position: fixed; /* 화면 최하단에 고정 */
  bottom: 0; /* 화면의 아래쪽에 위치 */
  left: 0; /* 화면의 왼쪽에 위치 */
  background-color: white; /* 배경색 추가 */
  z-index: 1000; /* 다른 요소들 위에 표시되도록 z-index 설정 */
`;

const FooterContent = styled.div`
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(163, 164, 166, 1);
  border-top-width: 1px;
  display: flex;
  min-height: 30px; /* 최소 높이 줄이기 */
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 15px 10px; /* 패딩 줄이기 */
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; /* 왼쪽 정렬 */
  gap: 8px; /* 간격을 줄여 더 많은 공간 확보 */
  padding-right: 25px; /* 오른쪽 패딩 추가 */
`;

const LikeSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px; /* 간격 줄이기 */
`;

const BackIcon = styled.img`
  aspect-ratio: 1.24;
  object-fit: contain;
  object-position: center;
  width: 20px;
  margin-right: 16px;
`;

const BackToList = styled.div`
  color: rgba(0, 0, 0, 1);
  margin-right: auto;
  font-size: 16px; /* 글씨 크기 줄이기 */
`;

const LikeIcon = styled.img`
  aspect-ratio: 1.09;
  object-fit: contain;
  object-position: center;
  width: 24px;
`;

const LikeCount = styled.div`
  color: rgba(163, 164, 166, 1);
`;

const CommentIcon = styled.img`
  aspect-ratio: 1.05;
  object-fit: contain;
  object-position: center;
  width: 24px;
`;

const CommentCount = styled.div`
  color: rgba(0, 0, 0, 1);
`;

const ShareIcon = styled.img`
  aspect-ratio: 1.18;
  object-fit: contain;
  object-position: center;
  width: 24px;
`;

export default Footer;