import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import ProductHeader from "../components/ProductDetails/ProductHeader";
import ProductImage from "../components/ProductDetails/ProductImage";
import ProductInfo from "../components/ProductDetails/ProductInfo";
import AdBanner from "../components/ProductDetails/AdBanner";
import TransactionMethods from "../components/ProductDetails/TransactionMethods";
import SellerInfo from "../components/ProductDetails/SellerInfo";
import SafeTransactionBanner from "../components/ProductDetails/SafeTransactionBanner";
import ProductContent from "../components/ProductDetails/ProductContent";
import Footer from "../components/ProductDetails/Footer";
import { useDelayedNavigation } from "../useDelayedNavigation";
import { ProductContext } from '../context/ProductContext';

const ProductDetails: React.FC = () => {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("ProductContext를 찾을 수 없습니다.");
  }

  const { productInfo, sellerInfo, productImage, isLoading } = context;

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <ProductHeader title="중고나라" />
      <Content>
        <ProductImage
          src={productImage}
          alt={productInfo.title}
        />
        <ProductInfo
          topCategory={productInfo.topCategory}
          bottomCategory={productInfo.bottomCategory}
          title={productInfo.title}
          description={productInfo.description}
          price={productInfo.price}
          status={productInfo.status}
        />
        <AdBanner
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a747704bf54dd1631773a735b8a9faf330c73a52b28090dda07858a7dfa02fb4?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
          alt="광고 배너"
        />
        <TransactionMethods />
        <SellerInfo
          name={sellerInfo.name}
          contact={sellerInfo.contact}
          reviews={sellerInfo.reviews}
        />
        <SafeTransactionBanner />
        <ProductContent 
          content={productInfo.content}
          date={productInfo.date}
          commentCount={0} 
        />
      </Content>
      <Footer 
        likeCount={0} 
        viewCount={productInfo.views}
        date={productInfo.date}
      />
    </Container>
  );
};

const Container = styled.main`
  max-width: 640px;
  margin: 0 auto;
  background-color: #fff;
  font-family: Inter, sans-serif;
  overflow-x: hidden;
  position: relative;
  padding-top: 64px; /* 헤더 높이만큼 패딩 */
  padding-bottom: 60px; /* 푸터 패딩 축소 */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  transform-origin: top left;
  @media (max-width: 640px) {
    transform: scale(0.8);
    width: 125%;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #27C360;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default ProductDetails;
