import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDelayedNavigation } from "../../useDelayedNavigation";

const SafeTransactionBanner: React.FC = () => {
  const navigate = useNavigate();
  const delayedNavigate = useDelayedNavigation(500); // 1초 딜레이

  const handleBannerClick = () => {
    window.alert("안심번호: 0505-686-6730");
  };

  const handlePaymentClick = async () => {
    console.log("Navigating to login page"); // 디버깅용 로그
    await delayedNavigate("/login");
    console.log("Navigation to login page complete"); // 디버깅용 로그
  };

  return (
    <Container>
      <BannerImage
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a8fc0eead39e901cce7b22d0d236c27d4dc0920bf9fb487962be81a50314b89?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
        alt="안전 거래 배너"
        onClick={handleBannerClick}
      />
      <ButtonsWrapper>
        <SafePaymentButton onClick={handlePaymentClick}>
          <ButtonIcon
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0fc0ba211328eb0e5e49246de7f458ae1fd96109777a06005ec161063ba7f470?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
            alt="안전결제 아이콘"
          />
          <ButtonText>안전결제</ButtonText>
        </SafePaymentButton>
        <FreeTransferButton onClick={handlePaymentClick}>
          <ButtonIcon
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/68510d067eb528aebdffa6b8a81922de4e036bdb7e090af8897782c931fd092b?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
            alt="무료 송금 아이콘"
          />
          <ButtonText>무료 송금</ButtonText>
        </FreeTransferButton>
      </ButtonsWrapper>
      <NaverPayImage
        src="/assets/naverpay.png"
        alt="네이버페이"
      />
      <Divider />
      <AdImage
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1d27b91bdde54eeb34b4c431340a33193d9ce4955e3f6a0cd9ea01963fe42e8e?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
        alt="광고 이미지"
      />
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  margin-top: 27px;
`;

const BannerImage = styled.img`
  width: 80%;
  max-width: 250px;
  aspect-ratio: 5.49;
  object-fit: contain;
  cursor: pointer;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 582px;
  padding-top: 20px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 13px;
  background-color: #00ab57;
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  flex: 1;
  max-width: 210px; // 변경된 너비
  height: 120px;
  @media (max-width: 991px) {
    padding: 10px 15px;
    height: 70px;
  }
`;

const SafePaymentButton = styled(Button)``;

const FreeTransferButton = styled(Button)``;

const ButtonIcon = styled.img`
  width: 89px;
  height: 39px;
`;

const ButtonText = styled.span`
  white-space: nowrap;
`;

const NaverPayImage = styled.img`
  width: 96%;
  max-width: 582px;
  height: auto;
  margin: 20px 20px; // 변경된 여백
`;

const Divider = styled.hr`
  width: 100%;
  max-width: 571px;
  border: 1px solid #d9d9d9;
  margin: 20px 0;
`;

const AdImage = styled.img`
  width: 96%;
  max-width: 576px;
  aspect-ratio: 3.26;
  object-fit: contain;
  margin: 20px 20px; // 변경된 여백
`;

export default SafeTransactionBanner;