import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { supabase } from '../lib/supabaseClient';
import { Product, Seller, Order } from '../types/supabase';

export interface ProductInfo {
  title: string;
  description: string;
  price: string;
  topCategory: string;
  bottomCategory: string;
  status: string;
  sellerName: string;
  sellerContact: string;
  content: string;
  date: string;
  views: number;
}

export interface OrderSummaryInfo {
  shippingLabel: string;
}

export interface TotalSummaryInfo {
  totalAmount: string;
  selectedPaymentMethod: string;
}

export interface SellerInfo {
  name: string;
  contact: string;
  reviews: {
    positive: number;
    neutral: number;
    negative: number;
  };
}

export interface OrderCompleteInfo {
  productName: string;
  price: string;
  bankName: string;
  accountNumber: string;
}

export interface ProductContextProps {
  productInfo: ProductInfo;
  setProductInfo: React.Dispatch<React.SetStateAction<ProductInfo>>;
  orderSummary: OrderSummaryInfo;
  setOrderSummary: React.Dispatch<React.SetStateAction<OrderSummaryInfo>>;
  totalSummary: TotalSummaryInfo;
  setTotalSummary: React.Dispatch<React.SetStateAction<TotalSummaryInfo>>;
  sellerInfo: SellerInfo;
  setSellerInfo: React.Dispatch<React.SetStateAction<SellerInfo>>;
  orderCompleteInfo: OrderCompleteInfo;
  setOrderCompleteInfo: React.Dispatch<React.SetStateAction<OrderCompleteInfo>>;
  productImage: string;
  setProductImage: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
}

export const ProductContext = createContext<ProductContextProps | undefined>(undefined);

export const ProductProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [productInfo, setProductInfo] = useState<ProductInfo>({
    title: "기본",
    description: "제목",
    price: "상품가격",
    topCategory: "홈 > 카테고리 분류 > 카테고리",
    bottomCategory: "[태그] 태그/태그/태그",
    status: "판매(안전)",
    sellerName: "-",
    sellerContact: "이메일주소 ㅣ 전화번호",
    content: "상품 설명",  // 초기값 설정
    date: "2024.11.07 21:46",  // 초기값 설정
    views: 17  // 초기값 설정
  });

  const [orderSummary, setOrderSummary] = useState<OrderSummaryInfo>({
    shippingLabel: "무료배송",
  });

  const [totalSummary, setTotalSummary] = useState<TotalSummaryInfo>({
    totalAmount: "총 결제금액",
    selectedPaymentMethod: "무통장입금",
  });

  const [sellerInfo, setSellerInfo] = useState<SellerInfo>({
    name: "판매자 닉네임",
    contact: "de*****@naver.com ㅣ 안심번호 이용중",
    reviews: {
      positive: 37,
      neutral: 1,
      negative: 0,
    },
  });

  const [orderCompleteInfo, setOrderCompleteInfo] = useState<OrderCompleteInfo>({
    productName: "주문완료 페이지 상품 제목",
    price: "상품가격",
    bankName: "우리은행",
    accountNumber: " 123-123-123456",
  });

  const [productImage, setProductImage] = useState<string>("https://i.imgur.com/dEcgxWl.png");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        // 상품 데이터 로드
        const { data: products, error: productError } = await supabase
          .from('products')
          .select('*')
          .eq('id', '4644495f-4bb4-40f3-8795-1f08ffa0765f')
          .single();

        if (productError) {
          console.error('Product fetch error:', productError);
          throw productError;
        }

        if (products) {
          // 상품 정보 설정
          setProductInfo({
            title: products.title,
            description: products.description,
            price: products.price,
            topCategory: products.top_category,
            bottomCategory: products.bottom_category,
            status: products.status,
            sellerName: products.seller_name,
            sellerContact: products.seller_contact,
            content: products.content,
            date: new Date(products.created_at).toLocaleString('ko-KR'),
            views: products.views
          });

          // 판매자 정보 설정 (products 테이블의 리뷰 데이터 사용)
          setSellerInfo({
            name: products.seller_name,
            contact: products.seller_contact,
            reviews: {
              positive: products.positive_reviews || 0,
              neutral: products.neutral_reviews || 0,
              negative: products.negative_reviews || 0
            }
          });

          setTotalSummary(prev => ({
            ...prev,
            totalAmount: products.price
          }));

          setOrderCompleteInfo({
            productName: products.title,
            price: products.price,
            bankName: products.bank_name || "케이뱅크",
            accountNumber: products.bank_account || "123-123-123456"
          });
          
          setProductImage(products.image_url);
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, []);

  return (
    <ProductContext.Provider value={{
      productInfo, setProductInfo,
      orderSummary, setOrderSummary,
      totalSummary, setTotalSummary,
      sellerInfo, setSellerInfo,
      orderCompleteInfo, setOrderCompleteInfo,
      productImage, setProductImage,
      isLoading
    }}>
      {children}
    </ProductContext.Provider>
  );
};
