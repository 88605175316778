import React, { useState } from "react";
import PaymentMethod from "./PaymentMethod";
import TotalSummary from "./TotalSummary";

const PayMethod: React.FC = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);

  return (
    <div>
      <PaymentMethod setSelectedPaymentMethod={setSelectedPaymentMethod} />
      <TotalSummary selectedPaymentMethod={selectedPaymentMethod || "결제수단을 선택하세요"} />
    </div>
  );
};

export default PayMethod;
