import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { supabase } from '../lib/supabaseClient';
import { ProductContext } from '../context/ProductContext';

interface AdminPageProps {}

const PRODUCT_ID = '4644495f-4bb4-40f3-8795-1f08ffa0765f'; // 고정된 상품 ID

const AdminPage: React.FC<AdminPageProps> = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    accountNumber: ""
  });

  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("ProductContext를 찾을 수 없습니다.");
  }

  const { 
    productInfo, setProductInfo,
    sellerInfo, setSellerInfo,
    productImage, setProductImage
  } = context;

  // ��그인 모달 표시 여부
  const [showLoginModal, setShowLoginModal] = useState(true);

  // 인증 상태 체크
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const { data: profile } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', user.id)
            .single();

          if (profile) {
            const subscriptionEnd = new Date(profile.subscription_end);
            const now = new Date();
            if (subscriptionEnd > now && profile.is_active) {
              setIsAuthenticated(true);
              setShowLoginModal(false);

              // 여기서 은행 정보 로드
              const { data: products } = await supabase
                .from('products')
                .select('*')
                .eq('id', PRODUCT_ID)
                .single();

              if (products) {
                setBankInfo({
                  bankName: products.bank_name || "",
                  accountNumber: products.bank_account || ""
                });
              }
              return;
            }
          }
        }
        setIsAuthenticated(false);
        setShowLoginModal(true);
      } catch (error) {
        console.error('Auth check error:', error);
        setIsAuthenticated(false);
        setShowLoginModal(true);
      }
    };

    checkAuth();
  }, []);

  // 로그인 처
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setMessage('');

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) throw error;

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', data.user.id)
        .single();

      if (profileError) throw new Error('프로필 정보를 불러올 수 없습니다.');

      if (profile) {
        const subscriptionEnd = new Date(profile.subscription_end);
        const now = new Date();

        if (subscriptionEnd > now && profile.is_active) {
          setIsAuthenticated(true);
          setShowLoginModal(false);
          setMessage('로그인 성공!');
        } else {
          throw new Error('구독이 만료되었습니다. 관리자에게 문의하세요.');
        }
      }
    } catch (error: any) {
      console.error('Login error:', error);
      setError(error.message || '로그인 실패');
      setIsAuthenticated(false);
      setShowLoginModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  // 홈으로 이동
  const handleGoHome = () => {
    navigate('/');
  };

  // 날짜 문자열을 ISO 형식으로 변환하는 헬퍼 함수
  const formatDateForInput = (dateStr: string) => {
    try {
      const date = new Date(dateStr.replace(/\./g, '-'));
      if (isNaN(date.getTime())) {
        return new Date().toISOString().slice(0, 16);
      }
      // 로컬 시간을 ISO 문자열로 변환
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    } catch (error) {
      console.error('Date formatting error:', error);
      return new Date().toISOString().slice(0, 16);
    }
  };

  // 데이터 저장
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // 상품 정보 업데이트
      const updateData = {
        title: productInfo.title,
        description: productInfo.description,
        price: productInfo.price,
        top_category: productInfo.topCategory,
        bottom_category: productInfo.bottomCategory,
        status: productInfo.status,
        seller_name: productInfo.sellerName,
        seller_contact: productInfo.sellerContact,
        content: productInfo.content,
        image_url: productImage,
        views: parseInt(productInfo.views.toString()) || 0, // 조회수를 명시적으로 숫자로 변환
        created_at: formatDateForInput(productInfo.date),
        bank_name: bankInfo.bankName,
        bank_account: bankInfo.accountNumber,
        positive_reviews: parseInt(sellerInfo.reviews.positive.toString()) || 0,
        neutral_reviews: parseInt(sellerInfo.reviews.neutral.toString()) || 0,
        negative_reviews: parseInt(sellerInfo.reviews.negative.toString()) || 0
      };

      const { error: productError } = await supabase
        .from('products')
        .update(updateData)
        .eq('id', PRODUCT_ID);

      if (productError) throw productError;

      alert('성공적으로 업데이트되었습니다.');
    } catch (error) {
      console.error('Error updating data:', error);
      alert('업데이트 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  // 날짜를 로컬 문자열로 변환하는 헬퍼 함수
  const formatDateForDisplay = (dateStr: string) => {
    try {
      const date = new Date(dateStr);
      return date.toLocaleString();
    } catch (error) {
      console.error('Date formatting error:', error);
      return new Date().toLocaleString();
    }
  };

  return (
    <Container>
      {showLoginModal && !isAuthenticated && (
        <ModalOverlay>
          <LoginBox>
            <Title>관리자 로그인</Title>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {message && <SuccessMessage>{message}</SuccessMessage>}
            <Form onSubmit={handleLogin}>
              <InputGroup>
                <Label>이메일</Label>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </InputGroup>
              <InputGroup>
                <Label>비밀번호</Label>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </InputGroup>
              <LoginButton type="submit" disabled={isLoading}>
                {isLoading ? '로그인 중...' : '로그인'}
              </LoginButton>
            </Form>
          </LoginBox>
        </ModalOverlay>
      )}

      {isAuthenticated ? (
        <>
          <Header>
            <h1>관리 페이지</h1>
            <Button onClick={handleGoHome}>나가기</Button>
          </Header>
          
          <Form onSubmit={handleSubmit}>
            <Section>
              <h2>상품 정보</h2>
              <InputGroup>
                <label htmlFor="title">상품명:</label>
                <input
                  type="text"
                  id="title"
                  value={productInfo.title}
                  onChange={(e) => setProductInfo(prev => ({ ...prev, title: e.target.value }))}
                  required
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="description">짧은 설명:</label>
                <input
                  type="text"
                  id="description"
                  value={productInfo.description}
                  onChange={(e) => setProductInfo(prev => ({ ...prev, description: e.target.value }))}
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="content">상세 설명:</label>
                <textarea
                  id="content"
                  value={productInfo.content}
                  onChange={(e) => setProductInfo(prev => ({ ...prev, content: e.target.value }))}
                  style={{ height: '200px' }}
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="price">가격:</label>
                <input
                  type="text"
                  id="price"
                  value={productInfo.price}
                  onChange={(e) => setProductInfo(prev => ({ ...prev, price: e.target.value }))}
                  required
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="topCategory">상위 카테고리:</label>
                <input
                  type="text"
                  id="topCategory"
                  value={productInfo.topCategory}
                  onChange={(e) => setProductInfo(prev => ({ ...prev, topCategory: e.target.value }))}
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="bottomCategory">하위 카테고리:</label>
                <input
                  type="text"
                  id="bottomCategory"
                  value={productInfo.bottomCategory}
                  onChange={(e) => setProductInfo(prev => ({ ...prev, bottomCategory: e.target.value }))}
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="status">상태:</label>
                <input
                  type="text"
                  id="status"
                  value={productInfo.status}
                  onChange={(e) => setProductInfo(prev => ({ ...prev, status: e.target.value }))}
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="sellerName">판매자 이름:</label>
                <input
                  type="text"
                  id="sellerName"
                  value={productInfo.sellerName}
                  onChange={(e) => setProductInfo(prev => ({ ...prev, sellerName: e.target.value }))}
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="sellerContact">판매자 연락처:</label>
                <input
                  type="text"
                  id="sellerContact"
                  value={productInfo.sellerContact}
                  onChange={(e) => setProductInfo(prev => ({ ...prev, sellerContact: e.target.value }))}
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="views">조회수:</label>
                <input
                  type="number"
                  id="views"
                  value={productInfo.views}
                  onChange={(e) => setProductInfo(prev => ({ 
                    ...prev, 
                    views: parseInt(e.target.value) || 0 
                  }))}
                  min="0"
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="date">날짜:</label>
                <input
                  type="datetime-local"
                  id="date"
                  value={formatDateForInput(productInfo.date)}
                  onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    console.log('Selected date:', selectedDate);
                    
                    const formattedDate = selectedDate.toLocaleString('ko-KR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false
                    });

                    setProductInfo(prev => ({
                      ...prev,
                      date: formattedDate
                    }));
                  }}
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="image">이미지 URL:</label>
                <input
                  type="text"
                  id="image"
                  value={productImage}
                  onChange={(e) => setProductImage(e.target.value)}
                />
              </InputGroup>
            </Section>

            <Section>
              <h2>판매자 정보</h2>
              <InputGroup>
                <label htmlFor="bankName">은행명:</label>
                <input
                  type="text"
                  id="bankName"
                  value={bankInfo.bankName}
                  onChange={(e) => setBankInfo(prev => ({ ...prev, bankName: e.target.value }))}
                  placeholder="예: 케이뱅크"
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="accountNumber">계좌번호:</label>
                <input
                  type="text"
                  id="accountNumber"
                  value={bankInfo.accountNumber}
                  onChange={(e) => setBankInfo(prev => ({ ...prev, accountNumber: e.target.value }))}
                  placeholder="예: 123-123-123456"
                />
              </InputGroup>
              <InputGroup>
                <label>리뷰 수:</label>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <input
                    type="number"
                    placeholder="긍정"
                    value={sellerInfo.reviews.positive}
                    onChange={(e) => setSellerInfo(prev => ({
                      ...prev,
                      reviews: {
                        ...prev.reviews,
                        positive: parseInt(e.target.value) || 0
                      }
                    }))}
                    min="0"
                  />
                  <input
                    type="number"
                    placeholder="중립"
                    value={sellerInfo.reviews.neutral}
                    onChange={(e) => setSellerInfo(prev => ({
                      ...prev,
                      reviews: {
                        ...prev.reviews,
                        neutral: parseInt(e.target.value) || 0
                      }
                    }))}
                    min="0"
                  />
                  <input
                    type="number"
                    placeholder="부정"
                    value={sellerInfo.reviews.negative}
                    onChange={(e) => setSellerInfo(prev => ({
                      ...prev,
                      reviews: {
                        ...prev.reviews,
                        negative: parseInt(e.target.value) || 0
                      }
                    }))}
                    min="0"
                  />
                </div>
              </InputGroup>
            </Section>

            <Button type="submit" disabled={isLoading}>
              {isLoading ? '저장 중...' : '저장하기'}
            </Button>
          </Form>
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Section = styled.section`
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const InputGroup = styled.div`
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input, textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }

  textarea {
    height: 100px;
    resize: vertical;
  }
`;

const Button = styled.button`
  background-color: #27C360;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #229A4C;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #666;
`;

const LoginBox = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
`;

const Label = styled.label`
  font-size: 0.9rem;
  color: #666;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #27C360;
  }
`;

const LoginButton = styled(Button)`
  margin-top: 1rem;
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f8d7da;
  border-radius: 4px;
`;

const SuccessMessage = styled.div`
  color: #28a745;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #d4edda;
  border-radius: 4px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export default AdminPage;
