/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <FooterImage1
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a000f899a82e4acd954fe5bb48c6ed9447f7453f5ebcac0437095eca42fba19?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
        alt="Footer image 1"
      />
      <FooterImage2
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d232df9844774b8921d69b2b0542cbaa5cc5a4f94c42b867d099788ebdca51d7?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
        alt="Footer image 2"
      />
      <FooterImage3
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8a4afcc70b3babaf5f7dd84823f8dbc1e314dde5d5c9a4aaf0f30df905be81c?placeholderIfAbsent=true&apiKey=4c6e8411f77f4625a1a5570dd0e6c4a9"
        alt="Footer image 3"
      />
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 117px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const FooterImage1 = styled.img`
  width: 100%;
  aspect-ratio: 5.15;
  object-fit: contain;
`;

const FooterImage2 = styled.img`
  width: 100%;
  aspect-ratio: 3.22;
  object-fit: contain;
  margin-top: 6px;
`;

const FooterImage3 = styled.img`
  width: 234px;
  aspect-ratio: 3.72;
  object-fit: contain;
  margin-top: 18px;
  max-width: 100%;
`;

export default Footer;
