import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDelayedNavigation } from "../../useDelayedNavigation"; 
import { ProductContext } from '../../context/ProductContext';

interface TotalSummaryProps {
  selectedPaymentMethod: string;
}


const TotalSummary: React.FC<TotalSummaryProps> = ({ selectedPaymentMethod }) => {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("ProductContext를 찾을 수 없습니다.");
  }

  const { totalSummary, setTotalSummary, productInfo } = context;
  const [isOrderButtonActive, setIsOrderButtonActive] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const delayedNavigate = useDelayedNavigation(1000);

  useEffect(() => {
    if (totalSummary.selectedPaymentMethod !== "결제수단을 선택하세요" && totalSummary.selectedPaymentMethod !== "" && isTermsChecked) {
      setIsOrderButtonActive(true);
    } else {
      setIsOrderButtonActive(false);
    }
  }, [totalSummary.selectedPaymentMethod, isTermsChecked]);

  const handleOrderClick = async () => {
    if (isOrderButtonActive && !isLoading) {
      setIsLoading(true);
      console.log("Order submitted, navigating to order-complete page"); 
      await delayedNavigate("/order-complete");
      console.log("Navigation to order-complete page complete"); 
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    setIsTermsChecked((prev) => !prev);
  };

  return (
    <Section>
      <TotalAmount>
        <AmountLabel>결제상세</AmountLabel>
      </TotalAmount>
      <PaymentDetails>
        <PaymentMethod>{totalSummary.selectedPaymentMethod}</PaymentMethod>
        <PaymentAmount>{productInfo.price}</PaymentAmount>
      </PaymentDetails>
      <TermsAgreement>
        <input
          type="checkbox"
          id="termsAgreement"
          checked={isTermsChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="termsAgreement">
          상품정보 및 결제 대행 서비스 이용약관 동의
        </label>
      </TermsAgreement>
      <OrderButton
        isActive={isOrderButtonActive}
        onClick={handleOrderClick}
        disabled={!isOrderButtonActive || isLoading}
      >
        {isLoading ? "주문 처리 중..." : "주문하기"}
      </OrderButton>
    </Section>
  );
};


const Section = styled.section`
  margin-top: 29px;
`;

const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 43px;
  font-weight: 654;
  padding-right: 20px;
`;

const AmountLabel = styled.span`
  color: #000;
  margin-left: 15px;
`;

const PaymentDetails = styled.div`
  background-color: #fff;
  border-radius: 20px;
  margin-top: 19px;
  padding: 44px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 35px;
  color: #000;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const PaymentMethod = styled.span`
  font-weight: 654;
`;

const PaymentAmount = styled.span`
  font-weight: 565;
  color: #27c360;
`;

const TermsAgreement = styled.div`
  margin-top: 42px;
  margin-left: 10px;
  font-size: 32px;
  color: #000;
  font-weight: 654;
  display: flex;
  align-items: center;
  gap: 10px;

  input[type="checkbox"] {
    width: 20px; /* 체크박스 너비 */
    height: 20px; /* 체크박스 높이 */
    transform: scale(1.5); /* 체크박스 크기 확대 */
    margin-left: 8px; /* 우측으로 이동 */
  }
`;

interface OrderButtonProps {
  isActive: boolean;
}

const OrderButton = styled.button<OrderButtonProps>`
  width: 100%;
  border-radius: 14px;
  background-color: ${({ isActive }) => (isActive ? "#27C360" : "#c2c6cf")};
  margin-top: 65px;
  min-height: 118px;
  color: #fff;
  font-size: 35px;
  font-weight: 600;
  border: none;
  cursor: ${({ isActive }) => (isActive ? "pointer" : "not-allowed")};
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export default TotalSummary;
